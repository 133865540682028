/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// This file includes polyfills needed by Angular and is loaded before the app.
// You can add your own extra polyfills to this file.

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// Other polyfills and shims go here, as needed.

// aws-sdk requires global to exist
(window as any).global = window;
(window as any).process = {
  env: { DEBUG: undefined },
};